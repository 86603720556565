import { Model, Store, OrderedStore } from 'store/Base';
import { observable } from 'mobx';
import { Ticket } from './Ticket';
import { Contact } from './Contact';
import { MilestoneStatus } from './MilestoneStatus';
import { Casts } from './Base';
import { Project } from './Project';
import { Team } from './Team';
import { Thread } from 'module/notes/store/Thread';
import { Module } from './Module';


export class Milestone extends Model {
    static backendResourceName = 'milestone';

    @observable id = null;
    @observable dueAt = null;
    @observable name = '';
    @observable ordering = 0;

    // If the milestone is on a roadmap, when should it be taken on (0 = now, 10 => Far future)
    @observable roadmapTime  = 0;
    @observable roadmapWidth = 0;


    @observable deliveredBy = this.relation(Contact)
    @observable receivedBy = this.relation(Contact)
    @observable status = this.relation(MilestoneStatus)
    @observable team = this.relation(Team)
    @observable project = this.relation(Project)
    @observable noteThread = this.relation(Thread)
    @observable module = this.relation(Module)
    @observable parent = this.relation(Milestone)
    @observable ticket = this.relation(Ticket)


    casts() {
        return {
            dueAt: Casts.nullableDatetime,
        };
    }
}

export class MilestoneStore extends Store {
    Model = Milestone;
    static backendResourceName = 'milestone';
}

export const OrderedMilestoneStore = OrderedStore(MilestoneStore, 'ordering');
