import { Model, Store } from 'store/Base';
import { observable } from 'mobx';


export class MilestoneStatus extends Model {
    static backendResourceName = 'milestone_status';

    @observable id = null;
    @observable name = '';
    @observable color = '';
    @observable default = false; // If true, this status applied when a new milestone is added.
}

export class MilestoneStatusStore extends Store {
    Model = MilestoneStatus;
    static backendResourceName = 'milestone_status';
}
