export default {
    nav: {
        main: {
            timeRegistration: 'Time registration',
            roadmap: 'Roadmap',
            operations: 'Operations',
            crm: 'CRM',
            infra: 'Infra',
            assets: 'Master data',
            iso: 'ISO',
            docs: 'Docs',
        },
        assets: {
            users: 'Users',
            globalValues: 'Settings',
            globalFiles: 'Special files',
        },
        audit: {
            accessLog: 'Access log',
        },
        account: {
            account: 'Account',
            changelog: 'Changelog'
        },
        crm: {
            entities: 'Entities',
            companies: 'Companies',
            contacts: 'Contacts',
            teams: 'Teams',
            contracts: 'Contracts'
        },
        operations: {
            tickets: 'Tickets',
            tags: 'Tags',
            milestones: 'Milestones',
            milestoneStatuses: 'Milestone statuses',
            project: 'Projects'
        },
        timeRegistration: {
            myTime: 'My Time',
            overview: 'Overview'
        },
        infra: {
            server: 'Servers'
        },
        modules: {
            overview: 'Modules',
            whale: {
                overview: 'Whale'
            }
        },
        iso: {
            checks: 'Checks',
            documents: {
                title: 'Documents',
                header: 'Document repository',
                documents: 'Documents',
                chapters: 'Chapters'
            }
        }
    },
    ticket: {
        overview: {
            title: 'Tickets',
        },
        field: {
            id: {
                label: 'ID'
            },
            number: {
                label: 'Ticket'
            },
            title: {
                label: 'Title'
            },
            assignedTo: {
                label: 'Assigned to'
            },
            timeSpent: {
                label: 'Time spent'
            }
        }
    },
    tag: {
        field: {
            id: {
                label: 'ID'
            },
            name: {
                label: 'Name'
            }
        },
        create: {
            title: 'Create new tag'
        },
        edit: {
            title: 'Edit tag'
        },
        overview: {
            addButton: 'Add tag'
        }
    },
    entity: {
         overview: {
            title: 'Entities',
            addButton: 'Add entity',
        },
        create: {
            title: 'Create entity',
        },
        edit: {
            title: 'Edit entity',
        },
        field: {
             name: {
                 label: 'Name'
             }
        }
    },
    user: {
        overview: {
            title: 'Users',
            addButton: 'Add user',
        },
        create: {
            title: 'Create user',
        },
        edit: {
            title: 'Edit user',
        },
        field: {
            fullName: { label: 'Name' },
            firstName: { label: 'First name' },
            lastName: { label: 'Last name' },
            email: { label: 'Email' },
            password: { label: 'Password' },
            dateJoined: { label: 'Added at' },
            abbreviation: { label: 'Abbreviation' },
            team: { label: 'Team' },
            groups: {
                label: 'Groups',
                value: {
                    operation_manager: 'Operation manager',
                    team_lead: 'Team lead',
                    employee: 'Employee',
                    superuser: 'Superuser',
                    admin: 'Admin',
                    manager: 'Manager',
                    experimental: 'Experimental',
                    iso: 'ISO',
                    sales: 'Sales'
                },
                empty: 'No groups assigned',
            },
            language: {
                label: 'Language'
            },
            isActive: {
                label: 'Users',
                options: {
                    all: 'All',
                    yes: 'Active',
                    no: 'Not active',
                }
            },
            external: { label: 'External' },
            exactId: { label: 'Exact ID' },
            autoClearTimeRegistration: { label: 'Automatically Clear Time Registration' },
        },
        login: {
            title: 'Login',
            forgotPasswordLink: 'Forgot password?',
            errors: {
                invalidCredentials: 'Username or password do not match',
                unknown: 'Unknown error, status code: {{status}}',
            },
            loginButton: 'Log In',
        },
        passwordForgot: {
            title: 'Forgot password',
            requestButton: 'Send e-mail',
            requestedEmailText:
                'An e-mail with a reset code has been sent to this e-mail address.',
            loggedInError: 'You are logged in. Password forgot only works when you are not logged in.',
        },
        resetPassword: {
            title: 'Reset password',
            resetButton: 'Reset',
            saveSuccess: 'Password is successfully reset.',
            loggedInError: 'You are logged in. Password reset only works when you are not logged in.',
        },
        changePassword: {
            title: 'Change password',
            field: {
                passwordOld: {
                    label: 'Current password',
                },
                passwordNew: {
                    label: 'New password',
                },
            },
            saveButton: 'Save',
        },
        account: {
            title: 'Account Details',
            logoutButton: 'Log out',
            info: {
                title: 'Account info',
            },
            password: {
                title: 'Change password',
            },
            logout: {
                title: 'Log out',
                confirm: 'Are you sure you want to logout?'
            },
        },
    },
    myFilter: {
        custom: 'Custom',
        action: {
            unsetDefault: 'Unset default',
            setDefault: 'Set default',
            setCurrent: 'Save current filters',
            delete: 'Delete',
        },
    },
    globalValue: {
        edit: {
            title: 'Edit {{key}}',
        },
        overview: {
            title: 'Settings',
        },
        field: {
            key: {
                label: 'Setting name',
            },
            value: {
                label: 'Value',
            },
            writable: {
                label: 'Writable?',
            },
            readable: {
                label: 'Readable?',
            },
        },
    },
    globalFile: {
        edit: {
            title: 'Edit special file',
        },
        overview: {
            title: 'Special files',
        },
        field: {
            key: {
                label: 'Name for file',
            },
            value: {
                label: 'File',
            },
            writable: {
                label: 'Writable?',
            },
            readable: {
                label: 'Readable?',
            },
        },
    },
    accessLogEntry: {
        overview: {
            title: 'Audit log',
        },
        field: {
            originalUser: {
                label: 'Original user',
            },
            masqueradingAsUser: {
                label: 'Masquerading as user',
            },
            ipAddress: {
                label: 'IP address',
            },
            method: {
                label: 'HTTP method',
            },
            endpoint: {
                label: 'Endpoint',
            },
            statusCode: {
                label: 'Status code',
            },
            additionalInfo: {
                label: 'Extra information',
            },
            requestTime: {
                label: 'Time of access',
            },
        },
    },
    tooltips: {
        masquerade: 'Masquerade',
        edit: 'Edit',
        delete: 'Delete',
        view: 'View',
        restore:'Restore',
        history: 'History',
        copy: 'Copy',
    },
    form: {
        search: 'Search',
        repeat: 'Repeat',
        fileType: {
            pdf: 'PDF',
            image: 'IMG',
            xls: 'XLS',
            any: 'Other',
            none: 'No file uploaded yet',
        },
        saveButton: 'Save',
        saveAndBackButton: 'Save & Back',
        saveAndNewButton: 'Save & New',
        deleteButton: 'Delete',
        multiPick: {
            searchPlaceholder: 'Select…',
            selectedText: '$1 of $2 selected',
            noneSelectedText: 'None selected',
            selectAllButton: 'All',
            selectNoneButton: 'None',
        },
        notifications: {
            saveSuccess: 'Saved successfully',
            deleteSuccess: 'Deleted successfully',
            saveError: 'Error with saving ({{status}})',
            saveValError: 'Not all information is filled in correctly',
            saveAuthError: 'Invalid credentials',
            newAppVersion:
                'New version available, click to reload page as soon as possible',
        },
        draftJs: {
            style: {
                'header-one': { label: 'H1' },
                'header-two': { label: 'H2' },
                'header-three': { label: 'H3' },
                'header-four': { label: 'H4' },
                'header-five': { label: 'H5' },
                'header-six': { label: 'H6' },
                blockquote: { label: 'Blockquote' },
                'unordered-list-item': { label: 'UL' },
                'ordered-list-item': { label: 'OL' },
                'code-block': { label: 'Code block' },
                BOLD: { label: 'Bold' },
                ITALIC: { label: 'Italic' },
                UNDERLINE: { label: 'Underline' },
                CODE: { label: 'Monospace' },
            },
        },
        submitButton: 'Submit',
        cancelButton: 'Cancel',
        addButton: 'Add',
        startDate: 'Start date',
        endDate: 'End date',
        deleteConfirmation: 'Are you sure you want to delete this entity?',
        restoreConfirmation: 'Are you sure you want to restore this entity?',
        smartDateRange: {
            value: {
                today: 'Today',
                yesterday: 'Yesterday',
                past_7_days: 'Past 7 Days',
                past_30_days: 'Past 30 Days',
                custom: 'Custom',
                last_month: 'Last Month',
                last_quarter: 'Last Quarter',
                last_year: 'Last Year',
                this_month: 'This Month',
                this_quarter: 'This Quarter',
                this_year: 'This Year',
                all_time: 'All Time',
            },
            period: {
                last: 'Last',
                this: 'This',
                value: {
                    month: 'Month',
                    quarter: 'Quarter',
                    year: 'Year',
                },
            },
        },
    },
    crash: {
        heading: 'Application crashed',
        subHeading1: 'We’re very sorry, but the application has crashed.',
        subHeading2: 'Our team has been notified.',
        reload: 'Reload page'
    },
    daycy: {
        week: {
            label: 'Wk',
            number: '{{week}}',
        },
        weekDay: {
            monday: 'Mo',
            tuesday: 'Tu',
            wednesday: 'We',
            thursday: 'Th',
            friday: 'Fr',
            saturday: 'Sa',
            sunday: 'Su',
        },
        month: {
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',
        },
    },
    pageNotFoundMessage: 'Page not found',
    brandingMessage: 'Powered by Code Yellow',



    milestone: {
        overview: {
            title: 'Milestones',
            addChild: 'Add child',
            new: 'NEW',
            dragInfo: 'After dragging milestones get inserted as a main milestone by default, hold shift to insert them as a sub milestone instead.',
        },
        field: {
            id: { label: 'ID' },
            status: { label: 'Status' },
            dueAt: { label: 'Due at' },
            name: { label: 'Name' },
            notes: { label: 'Notes' },
            ticket: { label: 'Ticket' },
            deliveredBy: { label: 'Delivered by' },
            receivedBy: { label: 'Received by' },
            team: { label: 'Team' },
            project: { label: 'Project' },
            company: { label: 'Company' },
            createdAt: { label: 'Created at' },
            noteThread: { label: 'Note thread' },
            ordering: { label: 'Ordering' },
            parent: { label: 'Parent' }
        },
        create: {
            title: 'Create milestone'
        }
    },
    milestoneStatus: {
        overview: {
            addButton: 'Add milestone status'
        },
        create: {
            title: 'Add milestone status'
        },
        edit: {
            title: 'Edit milestone status'
        },
        field: {
            id: {
                label: 'ID'
            },
            name: {
                label: 'Name'
            },
            color: {
                label: 'Color'
            },
            default: {
                label: 'Default'
            }
        }
    },
    timeRegistration: {
        overview: {
            title: 'Entries',
            stat: {
                total_duration: 'Total Duration',
                duration_per_user: 'Duration per User',
                duration_per_project: 'Duration per Project',
                duration_per_ticket: 'Duration per Ticket',
            },
            exportButton: 'Export',
        },
        create: {
            title: 'Add entry',
        },
        edit: {
            title: 'Edit entry',
        },
        myTime: {
            title: 'My Time',
            today: 'Today ({{date}})',
            yesterday: 'Yesterday ({{date}})',
            addButton: 'Add entry',
            addHolidayButton: 'Add holiday',
            addHoliday: {
                title: 'Add Holiday',
            },
            isAltered: 'This time registration has been altered by administration.',
            lastWeekDay: {
                monday: 'Last monday ({{date}})',
                tuesday: 'Last tuesday ({{date}})',
                wednesday: 'Last wednesday ({{date}})',
                thursday: 'Last thursday ({{date}})',
                friday: 'Last friday ({{date}})',
                saturday: 'Last saturday ({{date}})',
                sunday: 'Last sunday ({{date}})',
            },
            weekDay: {
                monday: 'Monday {{date}}',
                tuesday: 'Tuesday {{date}}',
                wednesday: 'Wednesday {{date}}',
                thursday: 'Thursday {{date}}',
                friday: 'Friday {{date}}',
                saturday: 'Saturday {{date}}',
                sunday: 'Sunday {{date}}',
            },
            changeDate: 'Change date',
        },
        field: {
            id: { label: 'ID' },
            projectsOrTickets: { label: 'Projects or tickets' },
            projects: { label: 'Projects' },
            user: { label: 'User' },
            tickets: { label: 'Tickets' },
            description: { label: 'Description' },
            date: { label: 'Date' },
            startedAt: { label: 'Started' },
            endedAt: { label: 'Ended' },
            duration: { label: 'Duration' },
            projectTeam: { label: 'Project team' },
            userTeam: { label: 'User team' }
        },
    },
    company: {
        overview: {
            addButton: 'Add company'
        },
        create: {
            title: 'Add company'
        },
        edit: {
            title: 'Edit company'
        },
        field: {
            name: {
                label: 'Name'
            },
            shortName: {
                label: 'Short Name'
            },
            mainContact: {
                label: 'Main contact'
            },
            responsible: {
                label: 'Responsible'
            },
            qaEmail: {
                label: 'QA e-mail'
            },
            communicationRhythm: {
                label: 'Communication rhythm'
            },
            invoiceAgreement: {
                label: 'Invoice agreement'
            }
        }
    },
    project: {
        create: {
            title: 'Add project'
        },
        edit: {
            title: 'Edit project'
        },
        overview: {
            addButton: 'Add project',
            title: 'Projects'
        },
        field: {
            id: {
                label: 'ID'
            },
            name: {
                label: 'Name'
            },
            company: {
                label: 'Company'
            },
            quotationLink: {
                label: 'Quotation'
            },
            hasActiveBudget: {
                label: 'Has active budget'
            },
            team: {
                label: 'Team'
            }
        }
    },
    contact: {
        overview: {
            title: 'Contacts',
            addButton: 'Add contact'
        },
        create: {
            title: 'Add Contact'
        },
        edit: {
            title: 'Edit contact'
        },
        field: {
            id: {
                label: 'ID'
            },
            name: {
                label: 'Name'
            },
            company: {
                label: 'Company'
            },
            fullName: {
                label: 'Name'
            },
            firstName: {
                label: 'First name'
            },
            lastName: {
                label: 'Last name'
            }
        }
    },
    team: {
        overview: {
            addButton: 'Add team'
        },
        create: {
            title: 'Add team'
        },
        edit: {
            title: 'Edit team'
        },
        field: {
            id: {
                label: 'ID'
            },
            company: {
                label: 'Company'
            },
            name: {
                label: 'Name'
            }
        }
    },
    history: {
        modal: {
            milestone: {
                title: 'History milestone'
            }
        }
    },
    phabricator: {
        linked: 'Linked with Phabricator'
    },
    server: {
        overview: {
            addButton: 'Add server',
            title: 'Server overview'
        },
        create: {
            title: 'Create server'
        },
        edit: {
            title: 'Edit server'
        },
        field: {
            id: {
              label: 'ID'
            },
            serverUrl: {
                label: 'Server URL'
            },
            company: {
                label: 'Company'
            },
            name: {
                label: 'Name'
            },
            iaeVersion: {
                label: 'IAE version'
            },
            branch: {
                label: 'Branch'
            },
            softwareVersion: {
                label: 'Software version'
            },
            buildDate: {
                label: 'Build date',
            },
            lastRetrievedDate: {
                label: 'Last retrieved'
            },
            team: {
                label: 'Team'
            },
            commitId: {
                label: 'Commit Hash'
            },
            commitNumber: {
                label: 'Commit Number'
            },
            serverType: {
                label: 'Type',
                option: {
                    staging: 'Staging',
                    uat: 'UAT',
                    production: 'Production'
                }
            },
            backups: {
                label: 'Backups',
                disabled: 'Disabled',
                error: 'No backup found',
                enabled: {
                    label: 'Backups',
                    option: {
                        enabled: 'Enabled',
                        both: 'Both',
                        disabled: 'Disabled'
                    }
                },
                type: {
                    label: 'Backup type',
                },
                date: {
                    label: 'Last backup created',
                },
                size: {
                    label: 'Last backup size (min. in bytes)',
                },
            },
            error: {
                label: 'Error'
            }
        }
    },
    thread: {
        field: {
            notes: {
                none: 'No notes',
                more: '({{count}} more)',
            },
        },
    },
    note: {
        field: {
            contents: { label: 'Contents' },
        },
    },
    module: {
        create: {
          title: 'Add module'
        },
        edit: {
            title: 'Edit module'
        },
        field: {
            id: {
                label: 'Id',
            },
            name: {
                label: 'Name'
            },
            team: {
                label: 'Team'
            },
            callSign: {
                label: 'Call Sign'
            },
            description: {
                label: 'Description & Relevant Process'
            },
            origin: {
                label: 'Origin project'
            },
            logo: {
                label: 'Logo'
            }
        }
    },
    chapter: {
        overview: {
            addButton: 'Add'
        },
        edit: {
            title: 'Edit chapter'
        },
        create: {
            title: 'Add chapter'
        },
        field: {
            order: {
                label: 'Order'
            },
            name: {
                label: 'Name'
            }
        }
    },
    document: {
        field: {
            chapter: {
                label: 'Chapter'
            },
            order: {
                label: 'Order'
            },
            name: {
                label: 'Name'
            },
            responsible: {
                label: 'Reponsible person'
            },
            documentUrl: {
                label: 'Url'
            },
            lastRevisionDate: {
                label: 'Last revision date'
            },
            type: {
                label: 'Document type',
                option: {
                    document: 'Document',
                    register: 'Register',
                    form: 'Form',
                    process: 'Process'
                }
            }
        },
        create: {
            title: 'Add document'
        }
    },
    workingStatus: {
        working: 'Working on {{subject}} since {{startedAt}}',
        notWorking: 'Not working',
        unspecified: 'unspecified',
    },
    contract: {
        overview: {
            addButton: 'Add contract',
            title: 'Contracts'
        },
        create: {
            title: 'Add contract'
        },
        edit: {
            title: 'Edit contract',
            sections: {
                project: 'Project',
                info: 'Project info',
                responsibilities: 'Responsibilities'
            }
        },
        field: {
            entity: {
                label: 'Entity'
            },
            company: {
                label: 'Company'
            },
            title: {
                label: 'Title'
            },
            reference: {
                label: 'Reference'
            },
            quotation: {
                label: 'Quotation'
            },
            description: {
                label: 'Description'
            },
            quotationLink: {
                label: 'Link quotation'
            },
            signedPdf: {
                label: 'Signed PDF'
            },
            customerContact: {
                label: 'Customer representative'
            },
            team: {
                label: 'Team'
            },
            projectLead: {
                label: 'Project Lead'
            },
            devLead: {
                label: 'Dev lead'
            },
            uxdLead: {
                label: 'UXD Lead'
            },
            status: {
                label: 'Status'
            },
            budgetType: {
                label: 'Budget Type'
            }
        },
        status: {
            'quotation draft': 'Quotation draft',
            'quotation sent': 'Quotation sent',
            'quotation on hold': 'Quotation on hold',
            'quotation rejected': 'Quotation rejected',
            'contract active': 'Contract active',
            'contract finished': 'Contract finished'
        }
    },
    budget: {
        create: {
            title: 'Add budget'
        },
        edit: {
            title: 'Edit budget'
        },
        field: {
            name: {
                label: 'Name'
            },
            type: {
                label: 'Type'
            },
            description: {
                label: 'Description'
            },
            startDate: {
                label: 'Start date'
            },
            endDate: {
                label: 'End date'
            },
            budgetMoney: {
                label: 'Budget'
            },
            budgetHours: {
                label: 'Hour budget'
            },
            extraHoursArePaid: {
                label: 'Extra hours are paid'
            },
            budgetHourlyRate: {
                label: 'Hourly rate for extra hours'
            },
            budgetHourlyMaxHours: {
                label: 'Maximum amount of extra hours'
            },
            project: {
                label: 'Project'
            }
        }
    },
};
